import React, { FunctionComponent, useEffect, useRef, useState} from 'react';
import { actions, selectors } from 'store';
import { Field, Formik } from 'formik';
import { Header, Modal, Grid, Divider, Segment, Form } from 'semantic-ui-react';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { Input, TextArea, Checkbox } from 'components/Form';
import { injectIntl } from 'react-intl';
import Spacer from 'components/core/Spacer/Spacer';
import { connect } from 'react-redux';
import { compose } from 'redux';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';
import { addLines } from 'interfaces/formSchema/customsValidators';
import { ServiceI } from 'interfaces/data/customsClerkInterfaces';
import { Cost, Label } from './styles';


type ModalAddLinesProps = {
  dispatch: (value: any) => void;
  companyId: number;
  category: any;
  data?: any;
  handleAddLines: (values: {
    id: number;
    action: 'Traces' | 'Douane';
    service: ServiceI;
    quantity: number;
    addTo: boolean;
    comments?: string;
  }) => void;
  handleUpdateLines: (values: {
    id: number
    action: 'Traces' | 'Douane';
    service: ServiceI;
    quantity: number;
    addTo: boolean;
    comments?: string;
  }) => void;
  handleClose: () => void;
  pricing: any;
  pricingLoading: boolean;
  billings: any;
};

const ModalAddLines: FunctionComponent<ModalAddLinesProps> = ({
  dispatch,
  companyId,
  category,
  data,
  handleAddLines,
  handleUpdateLines,
  handleClose,
  pricing,
  pricingLoading,
  billings,
}) => {

  const formikRef = useRef();

  const [options, setOptions]: any = useState()
  const [type, setType]: any = useState(null)
  const [priceList, setPriceList]: any = useState([])
  const [cost, setCost] = useState(null)
  const [defaultValues, setDefaultValues]: any = useState('')
  const [billingsList, setbillingList] = useState([]);

  useEffect(() => {
    dispatch(actions.companyClerksPricing.getOneClerksPricing({
      params: { companyId: companyId },
    }))
  }, [dispatch, companyId])

  useEffect(() => {
    dispatch(actions.customsClerk.getBillings());
    return () => {
      dispatch(
        actions.customsClerk.reset([
          'billings',
        ])
      );
    }
  }, [dispatch])

  useEffect(() => {
    category && setOptions([
      {
        key: 1,
        value: 'traces',
        text: 'Traces',
      },
      {
        key: 2,
        value: 'douanes',
        text: 'Douanes',
      }
    ])
  }, [category])

  useEffect(() => {
    // Add Billing List
    if(billings){
      billings.sort((prev, next) => {
        return prev.id - next.id
      })
      setbillingList(
        billings.map((e, index) => {
          return {
            key: index,
            value: {"category": e.category, "unitPrice": e.amount, "id": e.id},
            text: `${e.category} [${e.amount} €]`,
            }
          }
        )
      )
    }
  }, [billings])

  
  useEffect(() => {
    if(data.open){
      if(billingsList && pricing){
       const ref: any = formikRef.current;
       // create price list
       let customsPriceList: any = []
       pricing.customized.forEach((e) => {
        if(e.inCustoms){
          customsPriceList.push({
            key: e.id,
            value: {
              price: e.amount, 
              id: e.id, 
              description: e.category
            },
            text: `${e.category} - (${e.amount} €)`,
          })
        }
        })
        customsPriceList.length>0 && setPriceList(customsPriceList)

        // default selected values
        pricing.customized.forEach((e) => {
          if(data.data?.id){
            if(data.data?.Service_Id === e.id){        
              setDefaultValues(e.description)
              if(ref){
                ref.setFieldValue('service', {
                  price: e.amount, 
                  id: e.id, 
                  description: e.category
                })
                // set default cost when loading
                setCost(data.data?.quantity*e.amount)
              }
            }
          }
        })
      }
    }
}, [billingsList, data, pricing])

const segmentStyle = {
  margin: '0',
  paddingTop: '1.5rem',
};


  return (
    <Modal open={data.open} onClose={handleClose} closeIcon>
    <Segment style={segmentStyle}>
    {data && (
        <>
        <Header
          icon={`${data.data?.id ? 'edit' : 'plus circle'}`}
          content={`${data.data?.id ? 'Update' : 'Add'} Lines`}
        />
        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={(data.data && {
            id: (data.data && data.data.id) || null,
            action: data.data.action || '',
            comments: data.data.comments || '',
            quantity: data.data.quantity || 1,
            addTo: false,
            service: (data.data && {
              price: data.data.amount || '', 
              id: data.data.Service_Id || '', 
              description: data.data.description || ''
            }) || defaultValues || null,
          })}
          ref={formikRef}
          onSubmit={data.data && data.data.id ? handleUpdateLines : handleAddLines}
          validationSchema={addLines}
        >
       {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          values,
          handleSubmit,
        }: any) => (
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
            <Grid columns={1}>
                <Grid.Column>
                  <Grid.Row>
                  <Field
                    label={<MandatoryFieldLabel content='Actions' />}
                    name='action'
                    placeholder='Choose actions ...'
                    component={Dropdown}
                    options={options}
                    onChange={(e) => {
                        if(e === 'traces'){
                          setType(null)
                        }else{
                          setType(null)
                        }
                      }
                    }
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Row>
                <Divider hidden />
                <Grid.Row>
                  <Field
                    mandatory
                    name='id'
                    component={Input}
                    type='hidden'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Grid.Row>
                <Grid.Row>
                  {(type === 'traces') && (
                  <Field
                    mandatory
                     label='Cost'
                     name='cost'
                     placeholder='po...'
                     component={Input}
                     type='number'
                     meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                   />
                  )}
                  {type === null && (
                    <>
                     <Field
                        mandatory
                        label='Pricing'
                        name='service'
                        placeholder='Choose service...'
                        component={Dropdown}
                        loading={pricingLoading}
                        options={priceList}
                        meta={{
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                        }}
                        onChange={(e) => {
                          if(e){

                            if(values.quantity){
                              //setFieldValue('cost', (e.price ? e.price : 0) * values.quantity)
                              setCost((e.price ? e.price : 0) * values.quantity)
                            }else{
                              //setFieldValue('cost', e.price ? e.price : 0)
                              setCost(e.price ? e.price : 0)
                            }
                            setFieldValue('service', e)      
                          }
                        }}
                      />
                         <Divider hidden />
                       <Field
                    mandatory
                    label='Comments'
                    name='comments'
                    placeholder='po...'
                    component={TextArea}
                    type='textarea'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                    <Divider hidden />
                    <Field
                      mandatory
                      label='Quantity'
                      name='quantity'
                      component={Input}
                      type='number'
                      meta={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                      onChange={(e) => {
                          if(e){
                      
                            if(values.service){
                              setCost(e*values.service.price)
                              //setFieldValue('cost', e*values.service.price)
                            }
                          
                          }                     
                      }}
                   />
                    </>
                  )}              
                  <Divider hidden />
                  <Label>Cost</Label>
                   <Cost>{cost}</Cost>
                </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                <Grid.Row>
                  <Field
                    label='Add to clerks billings'
                    name='addTo'
                    component={Checkbox}
                    type='checkbox'
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }} 
                    onChange={() => {
                      setFieldValue('addTo', !values.addTo)
                    }}
                  />
                </Grid.Row>
                </Grid.Column>
            </Grid>
            <Spacer height='20px' />     
            <Button size='large' type='submit' content='Save' />
       </Form>
     </Modal.Content>
   )}
  </Formik>
  </>
  )}
  </Segment>
  </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
  pricing: selectors.companyClerksPricing.getOneClerksPricingSelector(state),
  billings: selectors.customsClerk.billingsSelector(state),
  pricingLoading: selectors.companyClerksPricing.loadingSelector(state),
  }
};


const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(ModalAddLines);
