import styled from "@emotion/styled";
import { Dropdown } from "semantic-ui-react";

export const IntrastatDropdown = styled(Dropdown)(({ props }: any) => ({
    borderRadius: "10px !important",
    border: "1px solid rgba(34,36,38,.15)",
    marginLeft: "10px",
}))

export const Absolute = styled.div`
    position: absolute;
    top: 0px;
    left: 380px;
    `
export const Traduction = styled.div(() => ({
    float: 'none',
    display: 'inline-block',
    fontSize: '11px',
    cursor: 'pointer',
    border: '1px solid rgb(212, 212, 213)',
    margin: '0 0 0 0.3rem',
    padding: '0.3em 0.5em',
    lineHeight: '1em',
    backgroundColor: '#fff',
    borderRadius: '0.5em'
}))
