export default {
  "intrastat.deb": "DEB",
  "intrastat.des": "DES",
  "intrastat.expedition": "Expéditions",
  "intrastat.introduction": "Introduction",
  "intrastat.credits": "Avoir",
  "intrastat.non_conforme": "Non conforme",
  "intrastat.none": "Néant",
  "intrastat.specifications": "Spécifications client",
  "intrastat.specifications.placholder": "Ecrire une note...",
  "intrastat.addIntranetRef": "Référence intranet",
  "intrastat.follow": "Suivre",
  "intrastat.unfollow": "Ne plus suivre",
  "intrastat.Close": "Clôturer le dossier",
  "intrastat.close": "clôturer",
  "intrastat.Open": "Ouverture du dossier",
  "intrastat.open": "ouverture",
  "intrastat.addDocs": "Ajouter des documents",
  "intrastat.fileName": "Nom",
  "intrastat.category": "Catégorie",
  "intrastat.date": "Date",
  "intrastat.actions": "Actions",
  "intrastat.control": "Contrôle",
  "intrastat.show": "Voir",
  "intrastat.delete": "supprimer",
  "intrastat.Delete": "Supprimer",
  "intrastat.validation": "Validation",
  "intrastat.operator": "Opérateur",
  "intrastat.error": "Erreur",
  "intrastat.company": "Société:",
  "intrastat.regularization": "Régularisation",
  "intrastat.filingPeriod": "Période:",
  "intrastat.filingCountry": "Pays de déclaration:",
  "intrastat.flow": "Flux:",
  "intrastat.createdAt": "Créée le:",
  "intrastat.emitter": "Emetteur:",
  "intrastat.followers": "Operateurs:",
  "intrastat.clientReference": "Référence client:",
  "intrastat.intranetReference": "Référence intranet:",
  "intrastat.purchaseOrder": "Numéro de commande:",
  "intrastat.doc.action": "{decision}, {action} le document",
  "intrastat.yes": "Oui",
  "intrastat.no": "Non",
  "intrastat.validation.alert": "Confirmez-vous la validation de ce document ?",
  "intrastat.deletion.alert": "Confirmez-vous la suppression de ce document ?",
  "intrastat.doc.validation": "",
  "intrastat.editData": "Editer",
  "intrastat.addIntranetReference": "Référence intranet",
  "intrastat.reportingPeriod": "Période déclarative",
  "intrastat.declarationType": "Type de déclaration",
  "intrastat.cDType": "Choisir le type...",
  "intrastat.cFlow": "Choisir le flux...",
  "intrastat.choose": "Sélectionnez...",
  "intrastat.pCategory": "Catégorie...",
  "intrastat.submit": "Valider",
  "intrastat.edit": "Editer la {declarationType}",
  "intrastat.action.Open": "Reouvrir",
  "intrastat.action.open": "reouvrir",
  "intrastat.action.Close": "Clôturer le dossier",
  "intrastat.action.close": "clôturer",
  "intrastat.detail.cancel": "Annuler la declaration",
  "intrastat.detail.open": "Ouvrir declaration",
  "intrastat.detail.Close": "Fermer la declaration",
  "intrastat.cancel": "Annulation",
  "intrastat.closeOpenDeclaration": "{status}",
  "intrastat.intranetRefRequired": "la référence intranet est obligatoire",
  "intrastat.closeIntrastat.header": "{openOrClose} {declarationType}",
  "intrastat.closeIntrastat": "Etes-vous sûr de vouloir {status} cette déclartion ?",
  "intrastat.closeIntrastat.alert": "Vous ne serez plus autorisé à apporter des modifications",
  "intrastat.reason": "Préciser les raisons de l'annulation",
  "intrastat.lReason": "Détail",
  "intrastat.addDocsArs": "Ajouter un document {declarationType}",
  "intrastat.allowedFiles": "Fichiers autorisés : fichiers compressés .zip, .pdf, .xlsx, .xls et .jpg",
  "intrastat.drop": "Déposez vos fichiers",
  "intrastat.lSplitDoc": "Diviser le document",
  "intrastat.split.info": 'Divisez et envoyez des documents PDF vers n\'importe quel référentiel. Ne supprimez pas les données de votre liste stockée localement jusqu\'à ce que vous décidiez d\'utiliser définitivement la fonction "Diviser le document"',
  "intrastat.split.header": "Gérer les documents: {id} {IntrastatFlow}",
  "intrastat.split.start": "Début",
  "intrastat.split.pnumber": "Chiffre...",
  "intrastat.split.end": "Fin",
  "intrastat.split.name": "Nom",
  "intrastat.split.pname": "Nom...",
  "intrastat.split.docCategory": "Catégorie de document: {type}",
  "intrastat.split.pdocCategory": "Sélectionner...",
  "intrastat.split.lDeclaCat": "Nom de la balise",
  "intrastat.split.pDeclaCat": "Catégorie de déclaration...",
  "intrastat.split.from": "De la page:",
  "intrastat.split.to": "à la page:",
  "intrastat.split.sendTo": "- Envoyer vers:",
  "intrastat.split.btn": "Diviser le document",
  "intrastat.tag.header": "Gérer les documents: {flow} {type}",
  "intrastat.tag.empty": "Vide",
  "intrastat.monit.displayedWithFilters": "Nombre de déclarations:",
  "intrastat.monit.searchFilters": "Recherche par filtre",
  "intrastat.monit.pOperator": "Opérateur...",
  "intrastat.monit.pFilingPeriod": "Période déclarative...",
  "intrastat.monit.pCountry": "Pays...",
  "intrastat.search.pIntrastatReference": "Référence...",
  "intrastat.search.pCompany": "Société...",
  "intrastat.search.pType": "Type...",
  "intrastat.search.pFlow": "Flux...",
  "intrastat.search.pIntranetReference": "Reference intranet...",
  "intrastat.search.pFilingPeriod": "Période déclarative...",
  "intrastat.search.pArchives": "Archives",
  "intrastat.search.apply": "Appliquer",
  "intrastat.search.reset": "Réinitialiser",
  "intrastat.note.close": "Fermer",
  "intrastat.table.FilingPeriod": "Période déclarative",
  "intrastat.table.Country": "Pays",
  "intrastat.table.CreatedAt": "Date de création",
  "intrastat.table.Customer": "Client",
  "intrastat.table.Type": "Type",
  "intrastat.table.Flow": "Flux",
  "intrastat.table.Emitter": "Déclarant",
  "intrastat.table.Followers": "Suivie",
  "intrastat.table.State": "Statuts",
  "intrastat.table.Dashboard": "Tableau de bord",
  "intrastat.prog.cancelled": "Annulée",
  "intrastat.prog.0": "En attente de traitement",
  "intrastat.prog.1": "Validation ASD",
  "intrastat.prog.2": "Validation client",
  "intrastat.prog.3": "Traitement terminé",
  "intrastat.prog.header": "progression",

  "intrastat.newShort": 'Nouvelle déclaration',
  "intrastat.new": 'Nouvelle déclaration intrastat',
  "intrastat.new.None": 'Néant',
  "intrastat.new.Reg": 'Régularisation des érreurs VAD Intra-UE',
  "intrastat.new.DeclaType": 'Type de déclaration',
  "intrastat.new.FlowType": 'Flux',
  "intrastat.new.Period": 'Période déclarative',
  "intrastat.new.FilingCountry": 'Pays de déclaration',
  "intrastat.new.ClientRef": 'Référence client',

  "intrastat.new.pDeclaType": 'Sélectionner le type de déclaration...',
  "intrastat.new.pFlowType": 'Sélectionner...',
  "intrastat.new.pFilingCountry": 'Sélectionner...',
  "intrastat.new.pClientRef": 'REF5765...',

  "intrastat.monit.subHeader": 'Intra-stats (operators)',
  "intrastat.monit.goToMonitoring": 'Tableau de bord',
  "intrastat.monit.Name": 'Nom',
  "intrastat.monit.Email": 'E-mail',
  "intrastat.monit.Country": 'Pays',
  "intrastat.monit.Code": 'Code iso',
};
