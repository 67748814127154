import { selectors, actions } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';

import {
  Grid,
  Header,
  Modal,
  Dropdown as Drop,
  Icon,
  Input
} from 'semantic-ui-react';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Iframe from './Iframe';
import { 
  Inner, 
  Left, 
  LeftArea, 
  Listing, 
  ListingEmpty, 
  ListingWrapper, 
  Nav, 
  RightArea, 
  SelectionArea, 
  SelectionLabel, Title, VertSep, content, drop, fsIcon, iframe } from './styles';
import CBoxIndeterminate from './CBoxIndeterminate';


const ModalFilterTagManager = ({
  companyId,
  collectionboxId,
  declarationType,
  data,
  categoriesDocuments,
  categoriesLoading,
  filterTagArchiveLoading,
  listFiles,
  listFilesLoading,
  groupFilesLoading,
  deleteFilesLoading,
  fileStateLoading,
  handleClose,
  dispatch,
  userAdminOrOp,
}: ModalTagManagerProps) => {

  const [documentUrl, setDocumentUrl]: any = useState(null)
  const [dropValue, setDropValue]: any = useState(null)
  const [categoriesList, setCategories]: any = useState(null)
  const [categoryValue, setCategoryValue]: any = useState(null)
  const [documentList, setDocumentList]: any = useState(null)
  const [isChecked, setIsChecked]: any = useState(null)
  const [selectedValue, setSelectedValue]: any = useState(null)
  const [fullscreenTv, setFullScreen]: any = useState(null)
  const [selectedMenu, setSelectedMenu]: any = useState(null)

  let fileList = []
  const folder = data.document.name.split('.')[0].split(' ').join('-').toLowerCase()

  const adaptedStatus = data.document.status === 'zip' ? 
    data.document.name.split('.')[0] : data.document.name.split('(')[1] ? 
    data.document.name.split(')')[1].toLowerCase() : data.document.name.toLowerCase();

  const collectionboxOptions = collectionboxId && [
    {key: 'a1', value: 1, text: `Introduction`},
    {key: 'a2', value: 2, text: `Expedition`},
  ]

  useEffect(() => {
    dispatch(actions.collectionbox.getFilesFullList({
      params: {
        companyId: companyId,
        collectionboxId: data.collectionboxId,
        documentId: data.document.id,
        folder: `${data.document.name.split('.')[0]}`,
        status: 'archive'
      }
    }))

    dispatch(actions.socket.subscribeCollectionBoxFiles(
      companyId, 
      data.collectionboxId, 
      data.document.id,
      adaptedStatus.split('.')[0],
      data.document.status
    ))

    return () => {
      dispatch(actions.collectionbox.reset(['listFiles', 'error']))
      dispatch(actions.socket.unsubscribeCollectionBoxFiles(
        companyId, 
        data.collectionboxId,
        data.document.id, 
        adaptedStatus.split('.')[0],
        data.document.status
        ))
    }

  }, [dispatch, collectionboxId, data, companyId, adaptedStatus])

  const fileExtention = (file) => {
    let extention
    switch(file){
      case file.endsWith('.pdf'):
        extention = file.slice(-3);
        break;

      case file.endsWith('.xls'):
        extention = file.slice(-3);
        break;

      case file.endsWith('.xlsx'):
        extention = file.slice(-4);
        break;

      default:
        extention = file.slice(-3);
    } 
    return extention.toLowerCase()
  }

  useEffect(() => {
    let tab = []
    if(listFiles && listFiles.Contents && listFiles.Contents.length>0){
      // from tmp folder
      if(listFiles.url){
        setDocumentUrl({
          url: listFiles[0].url, 
          context: 'session', 
          ext: fileExtention(listFiles[0].url),
        })
        setDocumentList(listFiles)
      }else{
       // from s3 repository
        listFiles.Contents.forEach((el, index) => {
          if(index === 0){
            setDocumentUrl({
              url: el.Key, 
              context: 's3', 
              ext: fileExtention(el.Key),
              tag: (el.tag && el.tag.Value) || null
            })
          }
          tab.push({
            name: el.Key.split('/')[3], 
            url: el.Key, 
            context: 's3', 
            ext: fileExtention(el.Key),
            tag: (el.tag && el.tag.Value) || null
          })
        })
        setDocumentList(tab)
      }
    }
    
    if(listFiles && !listFiles.Contents){
      let fileList = []
      listFiles.forEach(file => {
        fileList.push({
          id: file.id,
          name: file.name,
          url: `${data.document.path}/${file.name}`,
          context: 's3',   
          ext: fileExtention(file.name),
          tag: (file.tag && file.tag) || null,
          ch: false,
        }) 
      })
      setDocumentList(fileList)

      listFiles.forEach((el, index) => {
        if(index === 0){
          setDocumentUrl({
            url: `${data.document.path}/${el.name}`, 
            context: 's3', 
            ext: fileExtention(el.name),
            tag: (el.tag && el.tag) || null
          })
        }
      })
    }

  }, [listFiles, data.document.path])

  useEffect(() => {
    if(categoriesDocuments){
      setCategories(categoriesDocuments.map((e, index) => {
        return {
          key: index,
          value: e.id,
          text: e.name
        }
    }))
    }
  }, [categoriesDocuments])


    const filterTagArchives = (category: number, docCategory: number) => {
      if(category && category){
        let checkbox = document.querySelectorAll('input')
        let selectedPages = []

        checkbox.forEach((box, i) => {                   
          if(checkbox[i].checked){
            selectedPages.push(box.value)
          }
        })

        dispatch(actions.collectionbox.filterTagArchives({
          params: {
            companyId: companyId,
            collectionboxId: collectionboxId,
            selectedTag: category,
            documentId: data.document.id,
            category: docCategory,
          },
          data:  {
            taxPeriod: data.periodString,
            repository: folder,
            documents: selectedPages,    
            status: data.document.status,
            state: category
          }
        }))
        return () => {
          dispatch(actions.collectionbox.reset(['filterTagArchives', 'error']))
        }
      }
    }

    const deleteFiles = () => {
      let checkbox = document.querySelectorAll('input')
      let selectedFiles = []
      checkbox.forEach((box, i) => {                   
        if(checkbox[i].checked){
          selectedFiles.push(box.value)
        }
      })

      dispatch(actions.collectionbox.deleteFiles({
        params: {
          companyId: companyId,
          collectionboxId: collectionboxId,
          folder: folder,
        },
        data:{
          selectedFiles: selectedFiles
        }
      })) 
      return () => {
        dispatch(actions.collectionbox.reset(['deleteFiles', 'error']))
      }  
    }

    const createGroupedFile = () => {
      let checkbox = document.querySelectorAll('input')
      let selectedPages = []
      checkbox.forEach((box, i) => {                   
        if(checkbox[i].checked && checkbox[i].value !== ''){
          selectedPages.push(box.value)
        }
      })
      dispatch(actions.collectionbox.groupFiles({
        params: {
          companyId: companyId,
          collectionboxId: collectionboxId,
          documentId: data.document.id,
        },
        data:{
          selectedPages: selectedPages
        }
      })) 
      return () => {
        dispatch(actions.collectionbox.reset(['groupFiles', 'error']))
      }  
    }

    const loadDocument = (documentData: any) => {
      dispatch(
        actions.collectionbox.downloadDocument({
          params: {
            collectionboxId: collectionboxId,
            companyId: companyId,
            documentId: documentData.id
          },
          query: {
            documentName: documentData.name
          }
        })
      );
      return () => {
        dispatch(actions.collectionbox.reset(['downloadDoc', 'error']))
      }
    };

    const changeFileState = (fileId?: number, check?: boolean) => {
      let selectedFiles = []
      if(fileId === undefined){
        // select multiple
        let checkbox = document.querySelectorAll('input')
        checkbox.forEach((box, i) => {                      
          if(checkbox[i].checked){ 
            selectedFiles.push(checkbox[i].value)
          }
        })
        // loaders
        // fileTag must be true or false
        setSelectedValue(check)
      }else{
        // fileId: a file has been selected
        // loaders
        // fileTag must be true or false
        setSelectedValue(!check)
      }


      (selectedFiles.length || fileId) && dispatch(actions.collectionbox.changeFileState({
        params: {
          companyId: companyId,
          collectionboxId: collectionboxId,
          folder: folder,
          state: check ? true : 'null'
        },
        data:{
            selectedFiles: fileId ? fileId : selectedFiles
          }
      })) 

      // unset check values all to null
      selectedFiles.length && setIsChecked(null)

      return () => {
        dispatch(actions.collectionbox.reset(['changeFileState', 'error']))
      }  
    }

    const changeGlobalState = async (value: any, changeIndex: number, list: any) => {
      list.forEach((file, index) => {
        if(index === changeIndex){
          fileList.push({
            id: file.id,
            name: file.name,
            url: `${data.document.path}/${file.name}`,
            context: 's3',   
            ext: fileExtention(file.name),
            tag: (file.tag && file.tag) || null,
            ch: value,
          }) 
        }else{
          fileList.push({
            id: file.id,
            name: file.name,
            url: `${data.document.path}/${file.name}`,
            context: 's3',   
            ext: fileExtention(file.name),
            tag: (file.tag && file.tag) || null,
            ch: list[index].ch,
          }) 
        }
      })
      setDocumentList(fileList)
    }

    const changeAllState = async (value: any, list: any) => {
      list.forEach((file) => {
          fileList.push({
            id: file.id,
            name: file.name,
            url: `${data.document.path}/${file.name}`,
            context: 's3',   
            ext: fileExtention(file.name),
            tag: (file.tag && file.tag) || null,
            ch: value,
          }) 
        })
      setDocumentList(fileList)
      setIsChecked(!isChecked)
    }

   
useEffect(() => {


  // Function to enable group selection
  function enableGroupSelection(selector) {
     
   const checkboxes = Array.from(document.querySelectorAll(selector));
  
   // Create a MutationObserver instance
   const observer = new MutationObserver((mutations, observer) => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'checked') {
          const checkbox: any = mutation.target;
          if (lastChecked && checkbox.checked !== lastChecked.checked) {
            const start = checkboxes.indexOf(checkbox);
            const end = checkboxes.indexOf(lastChecked);
            const isChecked = checkbox.checked;
  
            checkboxes
              .slice(Math.min(start, end), Math.max(start, end) + 1)
              .forEach(cb => cb.checked = isChecked);
          }
        }
      });
      observer.disconnect();
   });
  
   // Options to observe changes in the 'checked' attribute
   const config = { attributes: true, attributeFilter: ['checked'] };
   let lastChecked = null;
   checkboxes.forEach(checkbox => {
      checkbox.addEventListener('click', event => {
        if (!lastChecked) {
          lastChecked = checkbox;
        } else if (event.shiftKey) {
          const start = checkboxes.indexOf(checkbox);
          const end = checkboxes.indexOf(lastChecked);
  
          checkboxes
            .slice(Math.min(start, end), Math.max(start, end) + 1)
            .forEach(cb => cb.checked = lastChecked.checked);
        }
        lastChecked = checkbox;
      });
  
      // Start observing the checkbox
      observer.observe(checkbox, config);
   });
  }
  
  // Initialize the group selection feature
  enableGroupSelection('.ui.input input[type="checkbox"]');
 
})
   

  return (
    <Modal style={fullscreenTv ? {'position': 'unset'} : {'position': 'relative', 'height': '90%'}} open={data.open} onClose={handleClose}  size='fullscreen' closeIcon>
      {fullscreenTv && (
        <Icon 
          name='tv'
          style={fsIcon}
          onClick={() => {
            setFullScreen(null)
          }} 
        />
      )}
      <Header icon='plus circle' content={`Manage tags: ${declarationType && declarationType}`}/>
      <Modal.Content style={content}>
      {userAdminOrOp && (<>
        <SelectionArea>
        <LeftArea>      
          <CBoxIndeterminate 
            id='Indeterminate' 
            name='Indeterminate'
            checked={isChecked}
            defaultIndeterminate={isChecked}
            onChange={async () => { 
              await changeAllState(!isChecked, documentList)
            }}
          />       
          <VertSep/>
          <SelectionLabel>Select</SelectionLabel>
        </LeftArea>
        <RightArea> 
          <Inner>         
            <Drop 
              key={'drop1'}                     
              name='drops' 
              style={drop} 
              placeholder='Choose state...' 
              search 
              selection
              fluid
              clearable
              options={collectionboxOptions}
              onChange={(e, {value}) => {
                if(value){
                  setDropValue(value);

                  if(typeof value === 'number' && value <= 2){
                    dispatch(actions.collectionbox.getDocsFromCat({
                      query: {type: 1}
                    }));
                  }
                  if(typeof value === 'number' && value > 2){
                    dispatch(actions.collectionbox.getDocsFromCat({
                      query: {type: 0}
                    }));
                  }             
                }
              }}
            /> 
          </Inner>
          <Inner>                                     
            <Drop
              key={'cat2'}       
              name='categories' 
              style={drop} 
              placeholder='Choose category...' 
              search 
              selection 
              fluid
              clearable
              loading={categoriesLoading}
              options={categoriesList}
              onChange={(e, { value }) => {             
                setCategoryValue(value)
              }}
            />
          </Inner>
        
            <Button
              key={'but1'}  
              disabled={filterTagArchiveLoading}
              {...(filterTagArchiveLoading && {
                icon: <Icon loading name='spinner' />,
              })}
              type='button'
              content='Submit'
              color='blue'
              onClick={() => {
                filterTagArchives(dropValue, categoryValue)
              }}
            />

         
            <VertSep/>
            <Button
              disabled={groupFilesLoading}
              {...(groupFilesLoading && {
                icon: <Icon loading name='spinner' />,
              })}
              type='button'
              content='Group files'
              color='blue'
              onClick={() => {
                createGroupedFile()
              }}
            />
            <VertSep/>
            <Button
              disabled={deleteFilesLoading}
              {...(deleteFilesLoading && {
                icon: <Icon loading name='spinner' />,
              })}
              type='button'
              content='Delete files'
              color='blue'
              onClick={() => {
                deleteFiles()
              }}
            />
            <VertSep/>
            <Button
              disabled={selectedValue && fileStateLoading}
              {...(selectedValue && fileStateLoading && {
                icon: <Icon loading name='spinner' />,
              })}
              type='button'
              content='Check'
              color='blue'
              onClick={() => {
                changeFileState(undefined, true)
              }}
            />
            <Button
              disabled={!selectedValue && fileStateLoading}
              {...(!selectedValue && fileStateLoading && {
                icon: <Icon loading name='spinner' />,
              })}
              type='button'
              content='Uncheck'
              color='blue'
              onClick={() => {
                changeFileState(undefined, false)
              }}
            />
            <VertSep/>
            <Icon 
             name='tv'
             style={{'cursor': 'pointer'}}
             onClick={() => {
                setFullScreen(true)
             }} 
             />
         
      </RightArea>
      </SelectionArea>
      </>
        )}
       
      <Grid columns={2}>   
          <Grid.Column width={7} style={ListingWrapper}> 
              {(
                (filterTagArchiveLoading || 
                listFilesLoading ||
                documentList === null)
               && 
              <Loader />) || (
                <Listing>              
                {documentList.length>0 && documentList.map((file, index) => {                                 
                  return (
                    <Nav key={index} className={selectedMenu === index ? 'pdfSelected' : ''} 
                    onClick={() => {               
                      setSelectedMenu(index)
                      }}>
                    <Left> 
                      {userAdminOrOp && (
                        <Input 
                          key={`i-${index}`} type="checkbox" checked={documentList[index].ch} value={file.name} 
                          data-file={file.id} style={{'margin': '9px', 'float': 'left', 'cursor': 'pointer'}}
                          onChange={async () => {                          
                            if(documentList[index].ch === false){                           
                              await changeGlobalState(true, index, documentList)                                          
                            }else{ 
                              setIsChecked(null)                      
                              await changeGlobalState(false, index, documentList)       
                            }                                          
                          }}
                        />                      
                      )}
                      <Title
                        onClick={() => setDocumentUrl(prevState => ({
                          ...prevState,
                            url: file.url,
                            ext: file.ext
                        }))}
                      >{file.ext} - {file.name}</Title>
               
                      {userAdminOrOp && (
                        <Icon 
                        size='large' 
                        key={`p-${index}`} 
                        name={file.tag ? "check circle" : "check circle outline"} 
                        data-file={{id: file.id, file: file.name}} 
                        style={{'margin': '9px', 'float': 'left', 'cursor': 'pointer'}}
                        onClick={() => {
                          changeFileState(file.id, file.tag)
                        }}
                        />
                      )}

                    </Left>  
                    </Nav>
                  )})
                }
                {documentList.length === 0 && (
                  <ListingEmpty>Empty</ListingEmpty>
                )}
              </Listing>
              )}      
          </Grid.Column>
      <Grid.Column width={9} style={fullscreenTv ? {'position': 'unset'} : {'position': 'relative'}}>
        {documentList && documentUrl && (
          <Iframe 
            style={iframe} 
            src={documentUrl}
            companyId={companyId}
            fullscreenTv={fullscreenTv}
            dispatch={dispatch}
            loadDocument={loadDocument}
            >
          </Iframe>
        )}
      </Grid.Column>
      </Grid>
    </Modal.Content>
  </Modal>);
};

interface ModalTagManagerProps {
  companyId: number,
  documentId: number,
  collectionboxId: string,
  declarationType: string,
  data: {
    open: boolean;
    flowCat: number;
    document: any;
    companyName: string;
    collectionboxId: string;
    periodString: string;
  },
  handleClose: () => void;
  handleTagOperations: () => void;
  finalize: (listFiles, session) => void;
  dispatch: (value: any) => void;
  filterTagArchiveLoading,
  listFiles,
  categoriesDocuments,
  categoriesLoading,
  listFilesLoading,
  groupFilesLoading,
  deleteFilesLoading,
  fileStateLoading,
  userAdminOrOp,

}

const mapStateToProps = (state) => {
  return {
    categoriesDocuments: selectors.collectionbox.getDocsFromCatSelector(state),
    categoriesLoading: selectors.collectionbox.getDocsFromCatLoadingSelector(state),
    listFiles: selectors.collectionbox.getFilesFullListSelector(state),
    listFilesLoading: selectors.collectionbox.getFilesFullListLoadingSelector(state),
    filterTag: selectors.collectionbox.tagSelector(state), 
    filterTagLoading: selectors.collectionbox.filterTagLoadingSelector(state),
    filterTagArchive: selectors.collectionbox.filterTagArchivesSelector(state), 
    filterTagArchiveLoading: selectors.collectionbox.filterTagArchivesLoadingSelector(state),
    tag: selectors.collectionbox.tagSelector(state),
    tagLoading: selectors.collectionbox.tagLoadingSelector(state),
    tagSuccess: selectors.collectionbox.tagSuccessSelector(state),
    singleFile: selectors.collectionbox.createSingleFileSelector(state),
    singleFileLoading: selectors.collectionbox.createSingleFileLoadingSelector(state),
    archiveAll: selectors.collectionbox.archiveAllSelector(state),
    archiveAllLoading: selectors.collectionbox.archiveAllLoadingSelector(state),
    bulkFilter: selectors.collectionbox.bulkFilterSelector(state),
    bulkFilterLoading: selectors.collectionbox.bulkFilterLoadingSelector(state),
    groupFilesLoading: selectors.collectionbox.groupFilesLoadingSelector(state),
    groupFiles: selectors.collectionbox.groupFilesSelector(state),
    deleteFilesLoading: selectors.collectionbox.deleteFilesLoadingSelector(state),
    fileStateLoading: selectors.collectionbox.fileStateLoadingSelector(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(ModalFilterTagManager);
