import { Formiz, useForm } from '@formiz/core';
import { isEmail } from '@formiz/validations';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';

import ImageCustom from 'components/core/ImageCustom/ImageCustom';
import Button from 'components/Button';
import ClickableText from 'components/ClickableText';
import images from 'assets/images';
import FormizSimpleInput from 'components/FormizField/FormizSimpleInput/FormizSimpleInput';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Spacer from 'components/core/Spacer/Spacer';
import authPath from 'routes/auth/paths';

const Index = ({ dispatch, isAuthenticated, history, intl, match }) => {
  const loginForm = useForm();

  useEffect(() => {
    if (match.params.token) {
      dispatch(
        actions.user.validateUser({ params: { token: match.params.token } })
      );

      history.push(authPath.login);
    }
    if (isAuthenticated) {
      history.push('/');
    }
  }, [dispatch, history, isAuthenticated, match]);

  const signIn = (values) => {
    dispatch(actions.auth.login({ data: values }));
  };

  return (
    <Wrapper
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <ImageCustom
        src={images.logo_asd_color}
        alt='Logo ASD'
        margin='50px'
        width='20em'
        height='189px'
        maxWidth='20em'
      />
      <Formiz connect={loginForm} onValidSubmit={signIn}>
        <form onSubmit={loginForm.submit} noValidate>
          <FormizSimpleInput
            type='email'
            name='email'
            label='Email'
            placeholder='test@test.com'
            required='Email is required'
            validations={[
              {
                rule: isEmail(),
                message: 'This is not a valid email',
              },
            ]}
          />
          <Spacer height='10px' />
          <FormizSimpleInput
            type='password'
            name='password'
            label='Password'
            placeholder='Password'
            required='Password is required'
          />
          <Spacer height='20px' />
          <Button
            type='submit'
            content={intl.formatMessage({
              id: 'button.login',
            })}
          />
        </form>
      </Formiz>
      <Spacer height='20px' />
      <ClickableText
        onClick={() => {
          history.push('/sign-up');
        }}
        textalign='center'
      >
        Create an account
      </ClickableText>
      <Spacer height='10px' />
      <ClickableText
        onClick={() => {
          history.push('/forgotten-password');
        }}
        textalign='center'
      >
        Forgot your password ?
      </ClickableText>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: selectors.auth.isAuthenticatedSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
