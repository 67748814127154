import * as Yup from 'yup';

import config from 'config';
import euCountries from 'config/euCountries.json';
import { VATnumbersSchema } from '../index';

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{8,})/;

const equalTo = (ref: any, msg: any) => {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path,
    },
    test: function (value: any) {
      return value === this.resolve(ref);
    },
  });
};
Yup.addMethod(Yup.string, 'equalTo', equalTo);

const vatNumber = Yup.string().when('country', (country, schema) => {

  return euCountries.includes(country)
    ? schema
      .test('vat', 'Invalid VAT number scheme', function (value) {
        const reg = new RegExp(VATnumbersSchema[this.parent.country]);
        return reg.test(value);
      })
      .required('Required')
    : schema;
})

export const addPartnerSchema = Yup.object().shape({
  uniqueNumber: Yup.string().trim().required('Required'),
});


export const serviceSchema = Yup.array(Yup.number());

export const textSchema = Yup.object().shape({
  message: Yup.string().required('Required'),
});

export const serviceSelectionSchema = Yup.object().shape({
  service: Yup.string().required('Required'),
  productId: Yup.number()
});

export const vatSchema = Yup.object().shape({
  country: Yup.string().required('Required'),
  vatNumber: Yup.string().required('Required'),
  isOss: Yup.boolean().default(false).required('Required'),
});

export const specificQuotesSchema = Yup.object().shape({
  id: Yup.number().required('Required'),
  category: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  amount: Yup.string().required('Required'),
  totalAmount: Yup.string().required('Required'),
  itemCostPrice: []
});

export const adresseSchema = Yup.object().shape({
  country: Yup.string().length(2).required('Required'),
  city: Yup.string().required('Required'),
  zipCode: Yup.string(),
  address1: Yup.string().required('Required'),
  address2: Yup.string(),
  headquarters: Yup.boolean().default(false),
  phoneNumber: Yup.string(),
});

export const createContactSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email().trim().required('Required'),
  phoneNumber: Yup.string(),
  uniqueNumber: Yup.string(),
  country: Yup.string().length(2).required('Required'),
  city: Yup.string().required('Required'),
  address1: Yup.string().required('Required'),
  address2: Yup.string(),
  zipCode: Yup.string().required('Required'),
  transporter: Yup.boolean(),
  vat: Yup.object().shape({
    country: Yup.string().required('Required'),
    vatNumber: vatNumber,
  }),
});

export const userPermissionsSchema = Yup.object().shape({
  permissions: Yup.array(),
  notification: Yup.boolean()
});

export const cbAssignedCountriesSchema = Yup.object().shape({
  countries: Yup.array()
});

export const vatObligationSchema = Yup.object().shape({
  country: Yup.string(),
  periodicity: Yup.number()
});

export const operatorSchema = Yup.object().shape({
  operator: Yup.number(),
  notes: Yup.string(),
});

export const updateUserSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email().trim().required('Required'),
  admin: Yup.boolean(),
  operator: Yup.boolean(),
  partnership: Yup.boolean(),
});

export const profileEditionSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email().trim().required('Required'),
  partnership: Yup.boolean(),
});

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  newPassword: Yup.string()
    .required('Required')
    .matches(
      passwordRegex,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  newPasswordConfirmation: Yup.string()
    .equalTo(Yup.ref('newPassword'), 'Passwords must match')
    .required('Required'),
});

export const kyaniteMassIntegrationSchema = Yup.object().shape({
  file: Yup.mixed()
    .required('Required')
    .test('fileType', 'Unsupported file format', (value) => {
      if (value) {
        const splitedName = value.name.split('.');
        return config.file.massIntegration.fileType.includes(
          splitedName[splitedName.length - 1]
        );
      }
    })
    .test(
      'fileSize',
      'File is too large',
      (value) => value && value.size < config.file.massIntegration.fileSize
    ),
});

export const agencySchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  address1: Yup.string().required('Required'),
  address2: Yup.string(),
  zipCode: Yup.string().required('Required'),
  vat: vatNumber,
});

export const newCompanySchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  uniqueNumber: Yup.string(),
  email: Yup.string().email().trim().required('Required'),
  phoneNumber: Yup.string(),
  country: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  address1: Yup.string().required('Required'),
  address2: Yup.string(),
  zipCode: Yup.string().required('Required'),
  transporter: Yup.boolean(),
  vatNumber: vatNumber,
  superOperator: Yup.boolean(),
});

export const updateCompanySchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email().trim().required('Required'),
  phoneNumber: Yup.string(),
  uniqueNumber: Yup.string(),
  asdId: Yup.string(),
  holdingAgency: Yup.string(),
  processingAgency: Yup.string(),
  country: Yup.string().required('Required'),
  transporter: Yup.boolean(),
  chatmail: Yup.boolean(),
  mailforchat: Yup.string().nullable(),
  mandates: Yup.boolean().nullable(),
  conex: Yup.boolean().nullable(),
});

export const addUserSchema = Yup.object().shape({
  email: Yup.string().email().trim().required('Required'),
  createUser: Yup.boolean(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  password: Yup.string(),
  cPassword: Yup.string(),
  role: Yup.string(),
  admin: Yup.boolean(),
  verification: Yup.boolean(),
});

export const addDriverSchema = Yup.object().shape({
  uniqueId: Yup.string().required('Required'),
  pseudo: Yup.string().required('Required'),
});

export const keyInfoSchema = Yup.object().shape({
  priority: Yup.number().required('Required'), 
  group: Yup.array().of(
      Yup.string()
      ).required('Required'),
  info: Yup.string().required('Required'),
  Type_Id: Yup.number()
});


export const updateDriverSchema = Yup.object().shape({
  pseudo: Yup.string().required('Required'),
  updatedAt: Yup.string(),
});


export const updateCompanyUserSchema = Yup.object().shape({
  role: Yup.string().required('Required'),
  admin: Yup.boolean(),
});

export const podProofUpload = Yup.object().shape({
  documentType: Yup.string(),
  file: Yup.mixed()
    .test('fileType', 'Unsupported file format', (value) => {
      if (value) {
        const splitedName = value.name.split('.');
        return config.file.podProof.fileType.includes(
          splitedName[splitedName.length - 1].toLowerCase()
        );
      }
    })
    .test('fileSize', 'File is too large', (value) => {
      return value && value.size < config.file.fiscalDeclaration.fileSize;
    })
    .required('Required'),
});

export const fiscalDeclarationSchema = Yup.object().shape({
  company: Yup.string().required('Required'),
  tier: Yup.string().nullable(true), // tmp
  activity: Yup.string().nullable(true),  // tmp
  date: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  workshop: Yup.string().required('Required'),
  file: Yup.mixed().required('Required')
    .test('fileType', 'Unsupported file format', (value) => {
      if (value) {
        const splitedName = value.name.split('.');
        return config.file.fiscalDeclaration.fileType.includes(
          splitedName[splitedName.length - 1].toLowerCase()
        );
      }
    })
    .test(
      'fileSize',
      'File is too large',
      (value) => value && value.size < config.file.fiscalDeclaration.fileSize
    ),
});

export const registerOssSchema = Yup.object().shape({
  company: Yup.number().required('Required'),
  cf: Yup.number().required('Required'),
  file: Yup.mixed()
    .test('fileType', 'Unsupported file format', (value) => {
      if (value)
        return config.file.OssIntegration.fileType.includes(value.type);
      else return true;
    })
    .test('fileSize', 'File is too large', (value) => {
      return value ? value.size < config.file.OssIntegration.fileSize : true;
    }),
});

export const contactRequestSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  company: Yup.string().required('Required'),
  email: Yup.string().email().trim().required('Required'),
  phoneNumber: Yup.string().nullable(),
  service: Yup.string().required('Required'),
  contactSubject: Yup.string().required('Required'),
  contactDescription: Yup.string().required('Required'),
  attachedFile: Yup.mixed()
    .test('fileType', 'Unsupported file format', (value) => {
      if (value)
        return config.file.contactAttachment.fileType.includes(value.type);
      else return true;
    })
    .test('fileSize', 'File is too large', (value) => {
      return value ? value.size < config.file.contactAttachment.fileSize : true;
    }),
});

export const periodAndCountryFiltersSchema = Yup.object().shape({
  reference: Yup.string(),
  clientReference: Yup.string(),
  startDate: Yup.string(),
  endDate: Yup.string(),
  buyer: Yup.number(),
  transporter: Yup.number(),
  country: Yup.string(),
  invoiceNumber: Yup.string(),
  awaiting: Yup.boolean(),
});

export const ssrCompleteSchema = Yup.object().shape({
  clientProspect: Yup.string().required('Required'),
  processData: Yup.array().required(),
});

export const ssrSendResultsSchema = Yup.object().shape({
  ccEmails: Yup.array().of(Yup.string().email().trim().required('Required')),
});
