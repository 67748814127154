import { resetState } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    login: null,
    update: null,
    logout: null,
    changeUserCompaniesSelected: null,
    getUserCompanies: null,
    newuser: null,
    updatePermissionsCompanySelected: null,
    reconnect: null,
  },
  errors: {
    login: null,
    update: null,
    logout: null,
    newuser: null,
    changeUserCompaniesSelected: null,
    getUserCompanies: null,
    updatePermissionsCompanySelected: null,
    reconnect: null,
  },
  loading: {
    login: false,
    update: false,
    logout: false,
    newuser: false,
    changeUserCompaniesSelected: false,
    getUserCompanies: false,
    updatePermissionsCompanySelected: false,
    reconnect: false,
  },
  connectedUser: false,
  user: null,
  newuser: null,
  userCompanies: null,
  companyUserPerms: null,
  companySelected: null,
  reconnect: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Login
    case actionTypes.LOGIN_LOADING:
      return produce(state, (draft) => {
        draft.loading.login = true;
      });
    case actionTypes.LOGIN_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.login = null;
        draft.loading.login = false;
        draft.success.login = true;
        draft.error = [];
        draft.connectedUser = true;
        draft.user = {
          id: action.payload.id,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          operator: action.payload.operator,
          role: action.payload.role,
          admin: action.payload.admin,
          superOperator: action.payload.superOperator,
          partnership: action.payload.partnership,
          companies: action.payload.companies,
				  userCompanyPerms: action.payload.userCompanyPerms,
        };
        if (action.payload.companies.length > 0) {
          draft.userCompanies = action.payload.companies;
          draft.companyUserPerms = action.payload.userCompanyPerms;
          draft.companySelected = action.payload.companies[0].id;
        }
      });
    case actionTypes.LOGIN_ERROR:
      return produce(state, (draft) => {
        draft.errors.login = true;
        draft.loading.login = false;
        draft.success.login = false;
        draft.error = action.error;
        draft.user = {};
      });


      // load user
      case actionTypes.USER_LOADING:
        return produce(state, (draft) => {
          draft.loading.newuser = true;
        });
      case actionTypes.USER_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.newuser = null;
          draft.loading.newuser = false;
          draft.success.newuser = true;
          draft.error = [];
          draft.connectedUser = true;
          draft.newuser = {
            id: action.payload.id,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            email: action.payload.email,
            operator: action.payload.operator,
            role: action.payload.role,
            admin: action.payload.admin,
            superOperator: action.payload.superOperator,
            partnership: action.payload.partnership,
            companies: action.payload.companies,
            userCompanyPerms: action.payload.userCompanyPerms,
          };
          if (action.payload.companies.length > 0) {
            draft.userCompanies = action.payload.companies;
            draft.companyUserPerms = action.payload.userCompanyPerms;
            draft.companySelected = action.payload.companies[0].id;
          }
        });
      case actionTypes.USER_ERROR:
        return produce(state, (draft) => {
          draft.errors.newuser = true;
          draft.loading.newuser = false;
          draft.success.newuser = false;
          draft.error = action.error;
          draft.newuser = {};
        });

    
       // load user
      case actionTypes.USER_RECONNECT_LOADING:
        return produce(state, (draft) => {
          draft.loading.login = true;
        });
      case actionTypes.USER_RECONNECT_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.login = null;
          draft.loading.login = false;
          draft.success.login = true;
          draft.error = [];
          draft.connectedUser = true;
          draft.user = {
            id: action.payload.id,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            email: action.payload.email,
            operator: action.payload.operator,
            role: action.payload.role,
            admin: action.payload.admin,
            superOperator: action.payload.superOperator,
            partnership: action.payload.partnership,
            companies: action.payload.companies,
            userCompanyPerms: action.payload.userCompanyPerms,
          };
          if (action.payload.companies.length > 0) {
            draft.userCompanies = action.payload.companies;
            draft.companyUserPerms = action.payload.userCompanyPerms;
            draft.companySelected = action.payload.companies[0].id;
          }
        });
      case actionTypes.USER_RECONNECT_ERROR:
        return produce(state, (draft) => {
          draft.errors.login = true;
          draft.loading.login = false;
          draft.success.login = false;
          draft.error = action.error;
          draft.user = {};
        });

    // Change company selected
    case actionTypes.CHANGE_COMPANY_SELECTED_LOADING:
      return produce(state, (draft) => {
        draft.loading.changeUserCompaniesSelected = true;
      });

    case actionTypes.CHANGE_COMPANY_SELECTED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.changeUserCompaniesSelected = null;
        draft.loading.changeUserCompaniesSelected = false;
        draft.success.changeUserCompaniesSelected = true;
        draft.companySelected = action.payload.userCompaniesSelected;
        draft.companyUserPerms = action.payload.userPerms;
      });

    case actionTypes.CHANGE_COMPANY_SELECTED_ERROR:
      return produce(state, (draft) => {
        draft.errors.changeUserCompaniesSelected = true;
        draft.loading.changeUserCompaniesSelected = false;
        draft.success.changeUserCompaniesSelected = false;
        !draft.error.includes('userPermissions') &&
          draft.error.push('userPermissions');
      });

    // update permissions company selected
    case actionTypes.UPDATE_PERMISSIONS_COMPANY_SELECTED_LOADING:
      return produce(state, (draft) => {
        draft.loading.getUserCompanies = true;
      });

    case actionTypes.UPDATE_PERMISSIONS_COMPANY_SELECTED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getUserCompanies = null;
        draft.loading.getUserCompanies = false;
        draft.success.getUserCompanies = true;
        draft.companyUserPerms = action.payload;
      });

    case actionTypes.UPDATE_PERMISSIONS_COMPANY_SELECTED_ERROR:
      return produce(state, (draft) => {
        draft.errors.getUserCompanies = true;
        draft.loading.getUserCompanies = false;
        draft.success.getUserCompanies = false;
        !draft.error.includes('companyUserPerms') &&
          draft.error.push('companyUserPerms');
      });

    // getUserCompany
    case actionTypes.GET_USER_COMPANIES_LOADING:
      return produce(state, (draft) => {
        draft.loading.getUserCompanies = true;
      });

    case actionTypes.GET_USER_COMPANIES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getUserCompanies = null;
        draft.loading.getUserCompanies = false;
        draft.success.getUserCompanies = true;
        draft.companySelected =
          draft.companySelected === null && action.payload.length > 0
            ? action.payload[0].id
            : draft.companySelected;
        draft.userCompanies = action.payload;
      });

    case actionTypes.GET_USER_COMPANIES_ERROR:
      return produce(state, (draft) => {
        draft.errors.getUserCompanies = true;
        draft.loading.getUserCompanies = false;
        draft.success.getUserCompanies = false;
        !draft.error.includes('userCompanies') &&
          draft.error.push('userCompanies');
      });

    // Update
    case actionTypes.UPDATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.update = true;
      });

    case actionTypes.UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.update = null;
        draft.loading.update = false;
        draft.success.update = true;
        draft.error = [];
        draft.user = action.payload;
      });

    case actionTypes.UPDATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.update = true;
        draft.loading.update = false;
        draft.success.update = false;
        draft.error = action.error;
        draft.user = {};
      });

    // Logout
    case actionTypes.LOGOUT_LOADING:
      return produce(state, (draft) => {
        draft.loading.logout = true;
      });

    case actionTypes.LOGOUT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.logout = null;
        draft.loading.logout = false;
        draft.connectedUser = false;
        draft.user = {};
        draft.success.logout = true;
      });

    case actionTypes.LOGOUT_ERROR:
      return produce(state, (draft) => {
        draft.errors.logout = action.error;
        draft.loading.logout = false;
        draft.success.logout = false;
      });

    case actionTypes.REMOVE_AUTH:
      return produce(state, (draft) => {
        draft.connectedUser = false;
        draft.user = null;
        draft.userCompanies = null;
        draft.companyUserPerms = null;
        draft.companySelected = null;
        draft.error = [];
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    default:
      return state;
  }
};
