import { routesApi } from "config/api";
import { dataReqI } from "interfaces";

export default ({ api }) => {
  return {
    login(data: dataReqI) {
      const loginApi = routesApi.user.login;
      return api.request({
        method: loginApi.method,
        route: loginApi.path,
        data: data.data,
      });
    },

    getUserPermissions(data: dataReqI) {
      return api.request({
        method: routesApi.company.user.getUserPermissions.method,
        route: routesApi.company.user.getUserPermissions.path,
        params: data.params,
      });
    },

   
    getUserCompanies(data: dataReqI) {
      return api.request({
        method: routesApi.company.user.getUserCompanies.method,
        route: routesApi.company.user.getUserCompanies.path,
        params: data.params,
      });
    },

    update(data: dataReqI) {
      const getUserApi = routesApi.user.getOne;
      return api.request({
        method: getUserApi.method,
        route: getUserApi.path,
        params: data.params,
      });
    },
    reconnectUser(data: dataReqI) {
      const getUserApi = routesApi.user.reconnect;
      return api.request({
        method: getUserApi.method,
        route: getUserApi.path,
        params: data.params,
      });
    },
    logout() {
      const logoutApi = routesApi.user.logout;
      return api.request({
        method: logoutApi.method,
        route: logoutApi.path,
      });
    },
  };
};
