export default {
  LOGIN: 'MY_ASD.AUTH.LOGIN',
  LOGIN_LOADING: 'MY_ASD.AUTH.LOGIN_LOADING',
  LOGIN_SUCCESS: 'MY_ASD.AUTH.LOGIN_SUCCESS',
  LOGIN_ERROR: 'MY_ASD.AUTH.LOGIN_ERROR',

  CHANGE_COMPANY_SELECTED: 'MY_ASD.AUTH.CHANGE_COMPANY_SELECTED',
  CHANGE_COMPANY_SELECTED_LOADING:
    'MY_ASD.AUTH.CHANGE_COMPANY_SELECTED_LOADING',
  CHANGE_COMPANY_SELECTED_SUCCESS:
    'MY_ASD.AUTH.CHANGE_COMPANY_SELECTED_SUCCESS',
  CHANGE_COMPANY_SELECTED_ERROR: 'MY_ASD.AUTH.CHANGE_COMPANY_SELECTED_ERROR',

  UPDATE_PERMISSIONS_COMPANY_SELECTED:
    'MY_ASD.AUTH.UPDATE_PERMISSIONS_COMPANY_SELECTED',
  UPDATE_PERMISSIONS_COMPANY_SELECTED_LOADING:
    'MY_ASD.AUTH.UPDATE_PERMISSIONS_COMPANY_SELECTED_LOADING',
  UPDATE_PERMISSIONS_COMPANY_SELECTED_SUCCESS:
    'MY_ASD.AUTH.UPDATE_PERMISSIONS_COMPANY_SELECTED_SUCCESS',
  UPDATE_PERMISSIONS_COMPANY_SELECTED_ERROR:
    'MY_ASD.AUTH.UPDATE_PERMISSIONS_COMPANY_SELECTED_ERROR',

  GET_USER_COMPANIES: 'MY_ASD.AUTH.GET_USER_COMPANIES',
  GET_USER_COMPANIES_LOADING: 'MY_ASD.AUTH.GET_USER_COMPANIES_LOADING',
  GET_USER_COMPANIES_SUCCESS: 'MY_ASD.AUTH.GET_USER_COMPANIES_SUCCESS',
  GET_USER_COMPANIES_ERROR: 'MY_ASD.AUTH.GET_USER_COMPANIES_ERROR',

  UPDATE: 'MY_ASD.AUTH.UPDATE',
  UPDATE_LOADING: 'MY_ASD.AUTH.UPDATE_LOADING',
  UPDATE_SUCCESS: 'MY_ASD.AUTH.UPDATE_SUCCESS',
  UPDATE_ERROR: 'MY_ASD.AUTH.UPDATE_ERROR',

  REMOVE_AUTH: 'MY_ASD.AUTH.REMOVE_AUTH',

  LOGOUT: 'MY_ASD.AUTH.LOGOUT',
  LOGOUT_LOADING: 'MY_ASD.AUTH.LOGOUT_LOADING',
  LOGOUT_SUCCESS: 'MY_ASD.AUTH.LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'MY_ASD.AUTH.LOGOUT_ERROR',

  USER: 'MY_ASD.AUTH.USER',
  USER_LOADING: 'MY_ASD.AUTH.USER_LOADING',
  USER_SUCCESS: 'MY_ASD.AUTH.USER_SUCCESS',
  USER_ERROR: 'MY_ASD.AUTH.USER_ERROR',

  USER_RECONNECT: 'MY_ASD.AUTH.USER_RECONNECT',
  USER_RECONNECT_LOADING: 'MY_ASD.AUTH.USER_RECONNECT_LOADING',
  USER_RECONNECT_SUCCESS: 'MY_ASD.AUTH.USER_RECONNECT_SUCCESS',
  USER_RECONNECT_ERROR: 'MY_ASD.AUTH.USER_RECONNECT_ERROR',

  RESET: 'MY_ASD.AUTH.RES',
};
