import actionTypes from './actionTypes';
import { dataReqI } from 'interfaces';

export default {
  login(data: dataReqI) {
    return {
      type: actionTypes.LOGIN,
      payload: data,
    };
  },
  changeCompanySelected(data) {
    return {
      type: actionTypes.CHANGE_COMPANY_SELECTED,
      payload: data,
    };
  },
  getNewUser() {
    return {
      type: actionTypes.USER,
    };
  },
  reconnectUser() {
    return {
      type: actionTypes.USER_RECONNECT,
    };
  },
  getUserCompanies(data: dataReqI) {
    return {
      type: actionTypes.GET_USER_COMPANIES,
      payload: data,
    };
  },
  update(data: dataReqI) {
    return {
      type: actionTypes.UPDATE,
      payload: data,
    };
  },
  logout() {
    return {
      type: actionTypes.LOGOUT,
    };
  },
  removeAuth() {
    return {
      type: actionTypes.REMOVE_AUTH,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys,
      },
    };
  },
};
