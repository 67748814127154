import { ThemeProvider } from 'emotion-theming';
import { createBrowserHistory } from 'history';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { selectors, actions } from 'store';
import Cookies from 'js-cookie';

import FloatingMessage from 'components/FloatingMessage/FloatingMessage';
import { routes } from 'routes';
import { PrivateRoute, PublicRoute } from 'components/Routes';
import theme from 'theme';
import NoMatch from 'views/NoMatch';
import Error from 'views/Error';
import { Body, Footer, Navigation, Main, Sidebar } from 'components/Layouts';
import NotificationModal from 'components/Layouts/Notification/Notification';
import CookiesBanner from 'components/CookiesBanner/CookiesBanner';

// SPW Dependencies
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { I18nextProvider } from 'react-i18next';

// SPW Resources
import EN from '../spw/src/assets/languages/en.json';
import FR from '../spw/src/assets/languages/fr.json';
import flagUK from '../spw/src/assets/images/emojis/flagUK.png';
import flagFR from '../spw/src/assets/images/emojis/flagFR.png';

// SPW Stylesheets
import '../index.css';

// SPW Flag Emojis
export const flagEmojis: { [key: string]: any } = {
  en: flagUK,
  fr: flagFR,
  // Add all of the flags associated with implemented languages.
};

// SPW Translation config
i18n.use(LanguageDetector).init({
  fallbackLng: 'en', // Default language fallback if translation is not available for the detected language.
  debug: true, // Enable debug logs in the console.
  interpolation: {
    escapeValue: false, // Do not escape HTML values.
  },
  // Place the translation JSON files for each supported language here.
  resources: {
    en: {
      translation: EN,
    },
    fr: {
      translation: FR,
    },

    // Add other languages as needed
  },
});

// SPW Current Language
export const currentLanguage = i18n.language;

const history = createBrowserHistory();

const App = ({
  dispatch,
  isAuthenticated,
  user,
  reconnect,
  companySelected,
  message,
  getError,
  httpCode,
  numberNotifications,
}: any) => {
  const [visible, setVisible] = useState(false);
  const [consentCookiesPolicy, setConsentCookiesPolicy] = useState(false);

  useEffect(() => {
    checkConsentCookies();
    const unlisten = history.listen(() => {
      checkConsentCookies();
      dispatch(actions.message.reset(['httpCode', 'message', 'error']));
    });

    return () => {
      unlisten();
    };
  }, [dispatch]);

  const checkConsentCookies = () => {
    Cookies.get('consentCookiesPolicy')
      ? setConsentCookiesPolicy(true)
      : setConsentCookiesPolicy(false);
  };

  useEffect(() => {
    document.title = `${
      (numberNotifications > 0 && `(${numberNotifications}) `) || ''
    }MyASD: software platform for international growth | ASD Group`;
  }, [numberNotifications]);

  const logout = () => {
    dispatch(actions.socket.unsubscribeUserCompanies(user.id));
    companySelected &&
      dispatch(
        actions.socket.unsubscribeUpdatePermissionsCompanySelected(
          user.id,
          companySelected
        )
      );
    dispatch(actions.auth.logout());
  };

  if (httpCode === 401) {
    dispatch(actions.message.reset(['httpCode']));
    dispatch(actions.auth.removeAuth());
  }

  useEffect(() => {
    user && dispatch(actions.socket.subscribeUserToken(user.id));
    return () => {
      user && dispatch(actions.socket.unsubscribeUserToken(user.id))
    }
  }, [dispatch, user])

  return (
    <ThemeProvider theme={theme.getTheme()}>
      <I18nextProvider i18n={i18n}>
        <Router history={history}>
          <Body>
            <Main>
              {!getError && message !== null && (
                <FloatingMessage dispatch={dispatch} />
              )}
              <CookiesBanner
                open={!consentCookiesPolicy}
                handleClose={() => setConsentCookiesPolicy(true)}
              />
              <Sidebar
                setvisible={setVisible}
                visible={visible}
                logout={logout}
              >
                {isAuthenticated &&
                  window.location.pathname.indexOf('spw') === -1 && (
                    <>
                      <Navigation
                        logout={logout}
                        history={history}
                        openSidebar={() => setVisible(true)}
                      />
                      <NotificationModal dispatch={dispatch} />
                    </>
                  )}
                {getError && (
                  <Route
                    history={history}
                    dispatch={dispatch}
                    component={Error}
                  />
                )}
                {!getError && (
                  <Switch>
                    {routes.map((route) => {
                      if (route.private) {
                        return (
                          <PrivateRoute
                            exact
                            path={route.path}
                            key={route.path}
                            authenticated={isAuthenticated}
                            authorized={route.authorizedRoles}
                            history={history}
                            dispatch={dispatch}
                            Component={route.component}
                          />
                        );
                      }
                      return (
                        <PublicRoute
                          exact
                          path={route.path}
                          key={route.path}
                          Component={route.component}
                        />
                      );
                    })}
                    <Route component={NoMatch} />
                  </Switch>
                )}
              </Sidebar>
              {window.location.pathname.indexOf('spw') === -1 && (
                <Footer history={history} user={user} />
              )}
            </Main>
          </Body>
        </Router>
      </I18nextProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: selectors.auth.isAuthenticatedSelector(state),
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    message: selectors.message.messageSelector(state),
    getError: selectors.message.getErrorSelector(state),
    httpCode: selectors.message.httpCodeSelector(state),
    numberNotifications:
      selectors.notifications.numberUnreadNotificationSelector(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(App);
