import actionTypes from 'store/auth/actionTypes';
import { actions } from 'store';
import routes from './routes';
import { actions as messageHandler } from 'store/message';

export default function ({ api }) {
  const request = routes({ api });
  return (store) => (next) => async (action) => {
    switch (action.type) {
      case actionTypes.LOGIN:
        try {
          next({ type: actionTypes.LOGIN_LOADING });
          const response = await request.login({
            ...action.payload,
          });
          next({
            type: actionTypes.LOGIN_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.LOGIN_ERROR,
            error,
          });
          store.dispatch(
            messageHandler.errorMessage(error.status, error.message)
          );
        }
        break;
       
      case actionTypes.CHANGE_COMPANY_SELECTED:
        try {
          next({ type: actionTypes.CHANGE_COMPANY_SELECTED_LOADING });
          const response = await request.getUserPermissions({
            ...action.payload,
          });
          if (store.getState().auth.companySelected) {
            store.dispatch(
              actions.socket.unsubscribeUpdatePermissionsCompanySelected(
                action.payload.params.userId,
                store.getState().auth.companySelected
              )
            );
            store.dispatch(
              actions.socket.subscribeUpdatePermissionsCompanySelected(
                action.payload.params.userId,
                store.getState().auth.companySelected
              )
            );
          }
          next({
            type: actionTypes.CHANGE_COMPANY_SELECTED_SUCCESS,
            payload: {
              userCompaniesSelected: action.payload.params.companyId,
              userPerms: response.data.data,
            },
          });
        } catch (error) {
          next({
            type: actionTypes.CHANGE_COMPANY_SELECTED_ERROR,
            error,
          });
        }
        break;
      case actionTypes.GET_USER_COMPANIES:
        try {
          next({ type: actionTypes.GET_USER_COMPANIES_LOADING });
          const response = await request.getUserCompanies({
            ...action.payload,
          });
          next({
            type: actionTypes.GET_USER_COMPANIES_SUCCESS,
            payload: response.data.data,
          });
          if (
            store.getState().auth.companySelected &&
            !response.data.data.find(
              (e) => e.id === store.getState().auth.companySelected
            )
          ) {
            if (response.data.data.length > 0) {
              const perms = await request.getUserPermissions({
                params: {
                  companyId: response.data.data[0].id,
                  userId: action.payload.params.userId,
                },
              });
              next({
                type: actionTypes.CHANGE_COMPANY_SELECTED_SUCCESS,
                payload: {
                  userCompaniesSelected: response.data.data[0].id,
                  userPerms: perms.data.data,
                },
              });
            } else {
              next({
                type: actionTypes.CHANGE_COMPANY_SELECTED_SUCCESS,
                payload: {
                  userCompaniesSelected: null,
                  userPerms: null,
                },
              });
            }
          }
        } catch (error) {
          next({
            type: actionTypes.GET_USER_COMPANIES_ERROR,
            error,
          });
        }
        break;
      case actionTypes.UPDATE_PERMISSIONS_COMPANY_SELECTED:
        try {
          next({
            type: actionTypes.UPDATE_PERMISSIONS_COMPANY_SELECTED_LOADING,
          });
          const response = await request.getUserPermissions({
            ...action.payload,
          });
          next({
            type: actionTypes.UPDATE_PERMISSIONS_COMPANY_SELECTED_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_PERMISSIONS_COMPANY_SELECTED_ERROR,
            error,
          });
        }
        break;
      case actionTypes.UPDATE:
        try {
          next({ type: actionTypes.UPDATE_LOADING });
          const response = await request.update({
            ...action.payload,
          });
          next({
            type: actionTypes.UPDATE_SUCCESS,
            payload: response.data.data,
          });
        } catch (error) {
          next({
            type: actionTypes.UPDATE_ERROR,
            error,
          });
        }
        break;

        case actionTypes.USER_RECONNECT:
          try {
            next({ type: actionTypes.USER_RECONNECT_LOADING });
            const response = await request.reconnectUser({
              ...action.payload
            });
            next({
              type: actionTypes.USER_RECONNECT_SUCCESS,
              payload: response.data.data,
            });
          } catch (error) {
            next({
              type: actionTypes.USER_RECONNECT_ERROR,
              error,
            });
          }
          break;

      case actionTypes.LOGOUT:
        try {
          next({ type: actionTypes.LOGOUT_LOADING });
          await request.logout();
          next({
            type: actionTypes.LOGOUT_SUCCESS,
          });
        } catch (error) {
          next({
            type: actionTypes.LOGOUT_ERROR,
            error,
          });
        }
        break;

      default:
        next(action);
        break;
    }
  };
}
